import { useState, useEffect, useRef } from 'react'
import classnames from 'classnames/bind'
import { ToastContainer } from 'react-toastify'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { isEmpty } from 'lodash'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'next-i18next'
import { install } from 'resize-observer'
import moment from 'moment'
import { useCookies } from 'react-cookie'

// Libs
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { useAuthorization } from '@/hooks/useAuthorization'
import { useMemberData } from '@/hooks/useMemberData'
import { useRefreshToken } from '@/hooks/useRefreshToken'
import { useLayout } from '@/hooks/useLayout'
import useCart from '@/hooks/useCart'
import useFixedPlayerByStorage from '@/hooks/useFixedPlayerByStorage'
import { handleCartDataUpdate } from '@/methods/handleCartDataUpdate'
import fastCheckout from '@/methods/fastCheckout'
import { updateSelectorStatus } from '@/redux/reducers/shoppingCart'
import { checkLogMode } from '@/utils/log'
import getSearchParams from '@/methods/url/getSearchParams'
import usePreviousRoute from '@/hooks/usePreviousRoute'
import useTimerClear from '@/hooks/useTimerClear'
import useFullScreen from '@/hooks/useFullScreen'
import { setLocationData, setIsOverseaUser } from '@/redux/reducers/common'
import { useUserLocale } from '@/hooks/useUserLocale'
import useSearchHotKey from '@/hooks/useSearchHotKey'
import { resetMemberData } from '@/redux/reducers/member'
import handleLogoutReturnToOriginalPath from '@/methods/handleLogoutReturnToOriginalPath'

// Components
import BasicLayout from '@/components/layouts/BasicLayout'
import PCHomeLayout from '@/components/layouts/PCHomeLayout'
import StrawberryLayout from '@/components/layouts/StrawberryLayout'
import Popup from '@/components/common/Popup'
import Loading from '@/components/common/Loading'
import Debug from './components/Debug'
import PortalWithPlayer from '@/components/common/PortalWithPlayer'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { STORAGE_DATA } from '@/constants/storageData'
import { ROOM_LIVE_TYPE, LIVE_SOURCE_TYPE } from '@/constants/liveRoom'
import { CartAction } from '@/constants/cart'
import { COUNTRY_CODE } from '@/constants/countryCode'
import { socialReferralCookieKey, SocialReferralKey } from '@/constants/cookieKey'
import { VALID_BRAND_NAME } from '@/constants/brand'
import { COOKIE_DATA, COOKIE_OPTIONS } from '@/constants/cookieKey'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

const FixedPlayer = dynamic(() => import('@/components/shared/FixedPlayer'), {
  ssr: false,
})
const IGExternal = dynamic(() => import('./components/IGExternal'), {
  ssr: false,
})

const MyApp = ({ Component, ...pageProps }: any) => {
  const { t } = useTranslation()

  const videoRef = useRef(null)
  const kickoffPopupRef = useRef(null)
  const networkErrorPopupRef = useRef(null)
  const brandName: string = pageProps.pageProps.brandName
  const isValidBrandName = Object.values(VALID_BRAND_NAME).includes(brandName)

  const [isCloseFixedPlayer, setIsCloseFixedPlayer] = useState(false)
  const [isShowIGExternal, setIsShowIGExternal] = useState(true)

  const fixedPlayerData = useAppSelector((state) => state.common?.fixedPlayerData)
  const memberData = useAppSelector((state) => state.member?.memberData)
  const roomData = useAppSelector((state) => state.room?.roomData)
  const socketData = useAppSelector((state) => state.socket)
  const showLoading = useAppSelector((state) => state.common.showLoading)
  const selectorStatus = useAppSelector((state) => state.shoppingCart?.selectorStatus)
  const logList = useAppSelector((state) => state.log?.logList)

  const isKickoff = socketData.isKickoff
  const isNetworkError = socketData.isNetworkError

  const dispatch = useAppDispatch()

  const isTestMode = getSearchParams()?.testMode === '1'
  const isIG = typeof window !== 'undefined' && window.navigator.userAgent.includes('Instagram')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pchomeUuidCookies, setPchomeUuidCookie, removePchomeUuidCookie] = useCookies([COOKIE_DATA.PCHOME_UUID])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pchomeNameExpiredCookies, setNameExpiredCookie, removeNameExpiredCookie] = useCookies([COOKIE_DATA.PCHOME_NAME_EXPIRED])

  const { memberInfoId } = memberData

  const isLive = roomData.liveType === ROOM_LIVE_TYPE.CURRENT
  const isPast = roomData.liveType === ROOM_LIVE_TYPE.PAST
  let Layout = null

  if (isValidBrandName) {
    switch (brandName) {
      case VALID_BRAND_NAME.PCHOME:
        Layout = PCHomeLayout
        break
      case VALID_BRAND_NAME.STRAWBERRY:
        Layout = StrawberryLayout
        break
      default:
        Layout = BasicLayout
        break
    }
  } else {
    Layout = BasicLayout
  }

  useSearchHotKey({})

  const router = useRouter()
  usePreviousRoute()

  const { pathname, isReady, query } = router
  const countryCodeFromQuery = query?.country_code
  const socialReferralId = query?.[SocialReferralKey.socialReferralId]
  const socialReferralFrom = query?.[SocialReferralKey.socialReferralFrom]

  useAuthorization()
  useMemberData()
  useRefreshToken()
  useLayout()
  useCart()
  useFixedPlayerByStorage()
  useTimerClear()
  useUserLocale()

  const isLiveRoomPath =
    pathname === ROUTE_PATH.LIVE_ROOM ||
    pathname === ROUTE_PATH.STRAWBERRY_LIVE_ROOM ||
    pathname === ROUTE_PATH.BRAND_LIVE_ROOM ||
    pathname === ROUTE_PATH.LIVE_ROOM_PRODUCT
  const isNotShoppingCartPage = pathname !== ROUTE_PATH.SHOPPINGCART

  const isMP4 = fixedPlayerData?.liveSourceType === LIVE_SOURCE_TYPE.MP4
  const fixedPlayerSrc = isMP4 ? fixedPlayerData?.liveSource : fixedPlayerData?.liveUrlInfo[0]?.streamUrl
  const isHostPlayerFlv = fixedPlayerData?.hostMemberInfoId === memberData?.memberInfoId

  const showFixedPlayer = !!fixedPlayerSrc && !isLiveRoomPath && !isHostPlayerFlv && !isCloseFixedPlayer && (isLive || isPast)

  const [, setCookie] = useCookies([socialReferralCookieKey])

  useEffect(() => {
    if (socialReferralFrom && socialReferralId) {
      setCookie(socialReferralCookieKey, { socialReferralFrom, socialReferralId }, { path: '/', maxAge: 14400, domain: window.location.hostname })
    }
  }, [isReady])

  useEffect(() => {
    if (!isEmpty(window)) {
      checkLogMode(dispatch)
    }
  }, [dispatch])

  useEffect(() => {
    // 舊瀏覽器不支援 ResizeObserver，所以另外安裝 resize-observer
    if (!window.ResizeObserver) {
      install()
    }
  }, [])

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5JTQPM7' })
  }, [])

  const { isFullScreen } = useFullScreen()

  useEffect(() => {
    // 如果 localstorage FAST_CHECKOUT_CACHE_DATA 有東西，代表要執行立即購買登入回來的流程
    if (memberInfoId && !isEmpty(window)) {
      const fastCheckoutCacheData: any = window.localStorage.getItem(STORAGE_DATA.FAST_CHECKOUT_CACHE_DATA)

      if (!isEmpty(fastCheckoutCacheData)) {
        const merchantId = JSON.parse(fastCheckoutCacheData)?.merchantId
        const skuId = JSON.parse(fastCheckoutCacheData)?.skuId
        const quantity = JSON.parse(fastCheckoutCacheData)?.quantity
        const roomId = JSON.parse(fastCheckoutCacheData)?.roomId
        const referralFrom = JSON.parse(fastCheckoutCacheData)?.referralFrom
        const referralId = JSON.parse(fastCheckoutCacheData)?.referralId

        const fastCheckoutItemPayload = {
          merchantId,
          skuId,
          quantity,
        }
        const skuList = [
          {
            skuId,
            quantity,
            roomId,
            referralFrom,
            referralId,
          },
        ]

        window.localStorage.removeItem(STORAGE_DATA.FAST_CHECKOUT_CACHE_DATA)
        handleCartDataUpdate({
          t,
          dispatch,
          action: CartAction.ADD,
          skuList,
          successCallback: () => fastCheckout({ router, dispatch, fastCheckoutItem: fastCheckoutItemPayload }),
        })
      }
    }
  }, [memberInfoId])

  useEffect(() => {
    if (isLiveRoomPath) setIsCloseFixedPlayer(false)
  }, [isLiveRoomPath])

  useEffect(() => {
    if (!isEmpty(selectorStatus)) {
      dispatch(updateSelectorStatus({}))
    }
  }, [isNotShoppingCartPage])

  useEffect(() => {
    // 如果 _document.tsx 內的 <Script/> strategy 改成其他的就要在 script 的 onLoad 處理
    const locationData = {
      _dittoCountryCode: window?._dittoCountryCode,
      _dittoCountryName: window?._dittoCountryName,
      _dittoContinentName: window._dittoContinentName,
      _dittoCurrency: window._dittoCurrency,
      _dittoLocale: window?._dittoLocale.replace('_', '-'),
      _dittoGTMContainer: window._dittoGTMContainer,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    const locationDataFromSessionStorage = JSON.parse(window.sessionStorage.getItem(STORAGE_DATA?.LOCATION_DATA) || '{}')
    if (isReady) {
      if (isEmpty(locationDataFromSessionStorage) || moment().isSameOrAfter(locationDataFromSessionStorage.expiredAt)) {
        // sessionStorage 空的就拿 window 的 ditto location data 做事
        const isOverseaUser = window._dittoCountryCode !== COUNTRY_CODE.TW || countryCodeFromQuery

        const countryCode = countryCodeFromQuery ? countryCodeFromQuery : window._dittoCountryCode

        dispatch(setIsOverseaUser({ isOverseaUser }))
        dispatch(setLocationData({ locationData: { ...locationData, _dittoCountryCode: countryCode } }))

        window.sessionStorage.setItem(STORAGE_DATA.LOCATION_DATA, JSON.stringify({ ...locationData, expiredAt: moment().add(2, 'h') }))
      } else {
        // sessionStorage 不是空的就拿 sessionStorage 的  locationData 做事
        const isOverseaUser = locationDataFromSessionStorage?._dittoCountryCode !== COUNTRY_CODE.TW || countryCodeFromQuery

        const countryCode = countryCodeFromQuery ? countryCodeFromQuery : locationDataFromSessionStorage._dittoCountryCode

        dispatch(setIsOverseaUser({ isOverseaUser: isOverseaUser }))
        dispatch(setLocationData({ locationData: { ...locationDataFromSessionStorage, _dittoCountryCode: countryCode } }))
      }
    }
  }, [isReady])

  useEffect(() => {
    if (typeof window !== 'undefined' && !isEmpty(brandName)) {
      const appEl = window.document.getElementById('app')

      if (appEl) {
        appEl.setAttribute('data-theme', brandName)
      }
    }
  }, [brandName])

  // PChome 合作案
  useEffect(() => {
    if (pathname === ROUTE_PATH.BRAND_LIVE_ROOM) {
      if (isEmpty(pchomeUuidCookies[COOKIE_DATA.PCHOME_UUID])) {
        const isDataFetched = !isEmpty(roomData.roomId) && memberData.isFetched

        if (isDataFetched) {
          const isHost = memberData?.memberInfoId === roomData?.memberInfoId

          if (!isHost) {
            dispatch(resetMemberData())
            localStorage.removeItem(STORAGE_DATA.TOKEN)
            // Jim 指定這裡要導頁 passport logout
            handleLogoutReturnToOriginalPath({ router })
          }
        }
      }
    } else {
      if (!isEmpty(pchomeUuidCookies[COOKIE_DATA.PCHOME_UUID])) {
        const isDataFetched = !isEmpty(roomData.roomId) && memberData.isFetched

        if (isDataFetched) {
          const isHost = memberData?.memberInfoId === roomData?.memberInfoId

          if (!isHost) {
            dispatch(resetMemberData())
            localStorage.removeItem(STORAGE_DATA.TOKEN)
            removePchomeUuidCookie(COOKIE_DATA.PCHOME_UUID, { path: '/', maxAge: 18316800, domain: COOKIE_OPTIONS.PCHOME.DOMAIN })
            removeNameExpiredCookie(COOKIE_DATA.PCHOME_NAME_EXPIRED, {
              path: '/',
              domain: COOKIE_OPTIONS.PCHOME.DOMAIN,
              expires: new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
            })
            // Jim 指定這裡要導頁 passport logout
            handleLogoutReturnToOriginalPath({ router })
          }
        }
      }
    }
  }, [pathname, memberData.isFetched, roomData.roomId])

  return (
    <div id='app' data-theme='basic' className={cx('app', 'app-theme')}>
      <Layout>
        <Component {...pageProps} />

        {showFixedPlayer && (
          <FixedPlayer
            videoRef={videoRef}
            roomId={fixedPlayerData?.roomId}
            src={fixedPlayerSrc}
            setIsCloseFixedPlayer={setIsCloseFixedPlayer}
            isShow={showFixedPlayer}
            isPast={isPast}
            hostMemberInfoId={fixedPlayerData?.hostMemberInfoId}
            coverPhotoUrl={fixedPlayerData?.coverPhotoUrl}
          />
        )}

        {isTestMode && logList?.length > 0 && <Debug logList={logList} />}

        {isIG && isShowIGExternal && <IGExternal setIsShowIGExternal={setIsShowIGExternal} />}

        <Popup
          ref={kickoffPopupRef}
          title={t('LiveRoom_KickoffPopup_Title')}
          confirmLabel={t('Common_Ok')}
          onConfirm={() => window.location.reload()}
          isOpen={isKickoff}
          hasCancel={false}
          canClose={false}
        />

        <Popup
          ref={networkErrorPopupRef}
          title={t('Common_Network_Error')}
          confirmLabel={t('Common_Ok')}
          onConfirm={() => window.location.reload()}
          isOpen={isNetworkError}
          hasCancel={false}
          canClose={false}
        />

        {showLoading && <Loading />}

        <PortalWithPlayer isFullScreen={isFullScreen}>
          <ToastContainer
            position='top-center'
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme='light'
            icon={false}
            closeButton={false}
          />
        </PortalWithPlayer>
      </Layout>
    </div>
  )
}

export default MyApp
