export const GTM_LOCATION = {
  INDEX: 'index', // 首頁
  NAVIGATION: 'navigationbar', // header
  PRODUCT_ITEM_DETAIL: 'item_detail', // 商品詳情
  LIVE_ROOM: 'liveroom', // 直播間
  LIVE: 'live',
  SHOPPINGCART: 'cart', // 購物車
  SEARCH_RESULT: 'search_result', // 搜尋結果
  PRODUCT_DETAIL: 'product_detail', // 商品詳情頁
  ORDER_DETAIL: 'order_detail', // 訂單詳情頁
  MY_ORDER: 'myorder', // 我的訂單
  CART: 'cart', // 購物車
  SHOPPINGCART_CHECKOUT: 'checkoutpage', // 購物車結帳頁
  USER_PROFILE: 'userprofile', // 會員中心
  FOOTER: 'footer', // footer
  HEADER_MENU: 'menu', // menu
  CHECKOUT_FAILED: 'checkout_failed', // 結帳失敗
  MALL: 'mall', // 商城
  ORDER_LIST: 'order_list', // 我的訂單
  ORDER_CONFIRMED: 'order_confirmed', // 確認訂單
  ME: 'me', // 會員中心
  ORDER_RESULT: 'thankupage', // 訂單結果頁
}
export enum GtmNavigationParams {
  LOGO = 'logo',
  LIVE = 'live',
  MALL = 'mall',
  CART = 'cart',
  SEARCH = 'search',
  BECOME_MERCHANT = 'Header_Secondary_Menu_Become_Merchant',
  BECOME_KOL = 'Header_Secondary_Menu_Become_KOL',
  FAQ = 'Header_Secondary_Menu_FAQ',
  TERMS_OF_SERVICE = 'Header_Secondary_Menu_Terms_Of_Service',
  RETURN_POLICY = 'Header_Secondary_Menu_Customer_Service_Center',
  MEMBER_LOGOUT = 'Header_Logout',
}

export enum GtmCategoryClassType {
  THEME = 'theme',
  CATEGORY = 'category',
  CALENDAR = 'calendar',
}

export enum GtmClickAdsClickText {
  BECOME_MERCHANT = 'Become_Merchant',
  BECOME_KOL = 'Become_Kol',
  RECRUITMENT_MERCHANT = 'Recruiitment_Merchant',
  RECRUITMENT_KOL = 'Recruitment_Kol',
}

export enum GtmCtaAddToCartText {
  ADD_TO_CART = 'Common_AddToCart',
  BUY_NOW = 'Common_BuyNow',
  CANCELED_ADD_TO_CART = 'Ordes_Detail_Canceled_Add_To_Cart',
}

export enum GtmUpdateCartAction {
  ADD = 'add_to_cart',
  REMOVE = 'remove_from_cart',
}

export enum GtmCtaRemoveFromCartMethod {
  DELETE_BUTTON = 'deletebutton',
  EDIT_AND_DELETE = 'edit_and_delete',
  QUANTITY_DECREASE = 'quantitydecrease',
}

export enum GtmClickViewCouponText {
  PRODUCT_DETAIL_CHECK_COUPON = 'Product_Detail_Check_Coupon',
  SHOPPINGCART_CHECK_COUPON = 'ShoppingCart_Footer_Placeholder_Text',
  SHOPPINGCART_CONFIRMATION_CHECK_COUPON = 'Field_Coupon_Placeholder',
}

export enum GtmClickRoomListTabType {
  LIVE = 'live',
  VIDEO = 'video',
}

export enum GtmClickLiveRoomPosition {
  ROOM_CARD = 'liveroomcard',
  ITEM_CARD = 'itemcard',
  PREVIEW_CARD = 'livepreviewcard',
  ORDER_LIST = 'orderlist',
  LIVE_PREVIEW = 'livepreview',
}
export enum GtmClickLiveRoomLiveStatus {
  LIVE = 'live',
  REVIEW = 'review',
  PREVIEW = 'preview',
}

export enum GtmShareMethod {
  FACEBOOK = 'Facebook',
  LINE = 'Line',
  LINK = 'Link',
}

export enum GtmShareContentType {
  CONTENT_TYPE_USER = 'user',
  CONTENT_TYPE_LIVEROOM = 'liveroom',
}

export enum GtmSocialChannelChannel {
  FACEBOOK = 'Facebook',
  LINE = 'Line',
  INSTAGRAM = 'Instagram',
}

export enum GtmItemListName {
  SEARCH_RESULT = '搜尋頁',
  RECOMMEND = '本店推薦',
  CATEGORY = '類別頁',
  THEME = '主題館',
  LIVE_ROOM = '直播間',
  MERCHANT = '店鋪首頁',
  ORDER_DETAIL = '訂單詳情',
  CALENDAR = '排播頁',
  POPULAR_PRODUCT = '活動專區',
}

export enum GtmClickChangeVarientText {
  CLICK_VARIENT = 'click_varient',
  RESELECT = 'Common_Confirm_Reselect',
}

export enum GtmLiveInteractionType {
  LIKE = 'like',
  COMMENT = 'comment',
  LIVE_CONNECT = 'liveconnect',
  LIVE_DISCONNECT = 'disconnect',
}

export enum GtmClickLiveRoomSetupPlayStatus {
  BACKWARD = 'backward',
  FORWARD = 'forward',
  PAUSE = 'pause',
}

export enum GtmClickCheckOrderClickText {
  MY_ORDER = 'Header_My_Orders',
  REVIEW_ORDER = 'OrderResult_View_Order',
}

export enum GtmClickLiveCalendarClickText {
  CALENDAR = 'LiveStreamingPreview_Title',
  MORE = 'Common_More',
}

export enum GtmClickLiveRoomSetupClickText {
  FULL_SCREEN = 'fullscreen',
}

export enum GtmAddBillingInfoAddInfo {
  SHIPPING_INFO = 'shipping_info',
  PAYMENT_INFO = 'payment_info',
}

export enum EGtmCtaGoAndPayClickText {
  GO_TO_PAY = 'Common_Go_To_Pay',
  GO_PAY = 'ShoppingCart_Action_Go_To_Payment',
  CONTINUE_TO_PAY = 'Orders_Detail_Go_Pay',
}

export const GtmPaymentMethod: any = {
  CREDIT: 'PaymentMethod_Credit',
  CVS: 'PaymentMethod_CVS',
  ATM: 'PaymentMethod_ATM',
  LINEPAY: 'LinePay',
  JKOPAY: 'PaymentMethod_JKOPAY',
}

export const GTM_EVENT = {
  LOGIN: 'cta_login',
  HEADER: {
    NAVIGATION: 'click_navigation',
    MEMBER: 'click_member',
  },
  CATEGORY: 'click_category',
  VIEW_PROMOTION: 'view_promotion',
  SELECT_PROMOTION: 'select_promotion',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_ITEM: 'view_item',
  CTA_ADD_TO_CART: 'cta_add_to_cart',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  CLICK_MERCHANT: 'click_merchant',
  CTA_REMOVE_FROM_CART: 'cta_remove_from_cart',
  CLICK_VIEW_COUPON: 'click_view_coupon',
  CLICK_ROOM_LIST_TYPE: 'click_roomlist_type',
  BOOK_CALENDAR_LIVE: 'book_calendar_live',
  CLICK_LIVE_ROOM: 'click_liveroom',
  CLICK_HOST: 'click_host',
  SHARE: 'share',
  SOCIAL_CHANNEL: 'social_channel',
  CLICK_CUSTOM_SERVICE: 'click_customersevice',
  VIEW_CART: 'view_cart',
  CLICK_CHANGE_VARIENT: 'click_change_varient', // 購物車重選商品規格
  LIVE_INTERACTION: 'live_interaction',
  CLICK_LIVE_ROOM_SETUP: 'click_liveroom_setup',
  SELECT_ITEM: 'select_item',
  VIEW_2024_SPINNER_EVENT: '2024_spinnerevent',
  ADD_BILLING_INFO: 'add_billing_info',
  CTA_GOANPAY: 'cta_goandpay',
  CLICK_CHECK_ORDER: 'click_check_order',
  CLICK_LIVE_CALENDAR: 'click_livecalendar',
  CLICK_ADS: 'click_ads',
  CLICK_FILTER: 'click_filter',
  BEGIN_CHECKOUT: 'begin_checkout',
  PURCHASE: 'purchase',
}
