import { createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
  data: any[]
  eventMenu: string | null
}

const initialState: IInitialState = {
  data: [],
  eventMenu: null,
}

export const mallSlice = createSlice({
  name: 'mall',
  initialState,
  reducers: {
    setCategoryMenuData: (state, { payload }) => {
      const { data } = payload

      state.data = data
    },
    setEventMenuData: (state, { payload }) => {
      const { data } = payload

      state.eventMenu = data
    },
  },
})

export const { setCategoryMenuData, setEventMenuData } = mallSlice.actions

export default mallSlice.reducer
