import { isEmpty } from 'lodash'
import { NextRouter } from 'next/router'

// Libs
import getSearchParams from '@/methods/url/getSearchParams'

// Constants
import { basePath } from '@/constants/routePath'

type ParamsType = {
  url?: string
  router: NextRouter
  pathname: string
  scroll?: boolean
  isReplace?: boolean
  locale?: string
  isOpen?: boolean
  shallow?: boolean
  query?: any
}

const getQueryExcludesSearchParams = ({ searchParams, query }: any) => {
  const lowerCaseKeysFromSearchParam = Object.keys(searchParams).map((keyName: string) => keyName.toLowerCase())
  const lowerCaseKeysFromQuery = Object.keys(query).map((keyName: string) => keyName.toLowerCase())
  const keysFromQuery = Object.keys(query)
  const result: any = {}

  lowerCaseKeysFromQuery.forEach((keyName: string, index: number) => {
    if (!lowerCaseKeysFromSearchParam.includes(keyName)) {
      result[keysFromQuery[index]] = query[keysFromQuery[index]]
    }
  })

  return result
}

const handleRoutePushWithQuery = ({
  url = '',
  router,
  pathname,
  scroll = true,
  isReplace = false,
  locale,
  isOpen,
  shallow = true, // 如果是切 locale 的話要把 shallow 設定為 false
  query = {},
}: ParamsType) => {
  const searchParamsFromPathname = getSearchParams(`${origin}${pathname}`) // pathname 如果有 search params 要一起處理

  const searchParams = { ...getSearchParams(url), ...searchParamsFromPathname }

  const pathnameWithoutParams = pathname.split('?')[0] // 如果 /pathname 本身有 query string 要拿掉

  const localeValue = locale || router?.locale // 如果沒有給 locale 就從 router 裡拿

  // isOpen 要自己組 basePath, locale 跟 search params
  const pathnameWithLocale = localeValue ? `/${localeValue}${pathnameWithoutParams}` : pathnameWithoutParams
  const as = `${basePath}${pathnameWithLocale}`
  const newSearchParamsArray = Object.entries(<any>searchParams).map(([key, value]) => {
    return { key, value }
  })

  const newSearchString = newSearchParamsArray
    .filter((item) => item?.value && !isEmpty(String(item.value)))
    .map((item) => `${item.key}=${item.value}`)
    .join('&')

  const newAs = newSearchString ? `${as}?${newSearchString}` : as
  const queryExcludesSearchParams = getQueryExcludesSearchParams({ searchParams, query })

  if (isOpen) {
    window.open(newAs)
  } else if (isReplace) {
    router.replace({ pathname: pathnameWithoutParams, query: { ...searchParams, ...queryExcludesSearchParams } }, undefined, {
      scroll,
      locale: localeValue,
      shallow,
    })
  } else {
    router.push({ pathname: pathnameWithoutParams, query: { ...searchParams, ...queryExcludesSearchParams } }, undefined, {
      scroll,
      locale: localeValue,
      shallow,
    })
  }
}

export default handleRoutePushWithQuery
