import React, { useEffect, type ReactNode } from 'react'
import { useAppSelector } from '@/redux/hooks'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { ORDER_TYPE } from '@/constants/order'
import useMedia from '@/hooks/useMedia'
import { isNull, isEmpty } from 'lodash'

// Libs
import { isClientSideShopApp } from '@/utils/navigator'

// Components
import Header from './components/Header'
import Backdrop from '@/components/common/Backdrop'
import Footer from './components/Footer'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { STORAGE_DATA } from '@/constants/storageData'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

type BasicLayoutProps = {
  children: ReactNode
}

const BasicLayout = (props: BasicLayoutProps) => {
  const { children } = props

  const common = useAppSelector<any>((state) => state.common)
  const router = useRouter()

  const isLivePage = router?.pathname === ROUTE_PATH.LIVE_ROOM
  const isHeaderHidden = isClientSideShopApp
  const isFullHeight = isLivePage // 高度固定為 viewport height 的頁面

  const hiddenFooterPages = [
    {
      path: ROUTE_PATH.SHOPPINGCART,
      mobileHidden: false,
      tabletHidden: false,
    },
    {
      path: ROUTE_PATH.ORDERS,
      mobileHidden: false,
      tabletHidden: false,
    },
    {
      path: ROUTE_PATH.LIVE_ROOM,
      mobileHidden: false,
      tabletHidden: false,
    },
    {
      path: ROUTE_PATH.PRODUCT_DETAIL,
      mobileHidden: true,
      tabletHidden: true,
    },
    {
      path: ROUTE_PATH.MALL,
    },
    {
      path: ROUTE_PATH.EVENT_DETAIL,
    },
    {
      path: ROUTE_PATH.SEARCH_APP,
      mobileHidden: true,
      tabletHidden: true,
    },
  ]

  const { isMobile, isTablet } = useMedia()

  const showFooter = () => {
    if (isClientSideShopApp) return false

    const hiddenPage = hiddenFooterPages.find((item) => router.pathname === item.path)

    // 沒找到的話 代表是要顯示footer的頁面
    if (!hiddenPage) {
      return true
    }
    // 有些頁面在 平板或手機 不顯示footer
    // 如果 hiddenPage.mobileHidden 是 true 但 不是手機
    // 並且 hiddenPage.tabletHidden 是 true 但 不是平板 就要顯示footer
    if (hiddenPage.mobileHidden && !isMobile && hiddenPage.tabletHidden && !isTablet) {
      return true
    }
    // ORDER 額外處理
    if (hiddenPage.path === ROUTE_PATH.ORDERS) {
      const { type, order } = router.query
      if ((type === ORDER_TYPE.CHECKING || type === `${ORDER_TYPE.CANCELED},${ORDER_TYPE.INVALID}`) && order) {
        return false
      }
    }
    return false
  }

  const showHeaderTip = (): boolean => {
    if (isClientSideShopApp) return false

    const DISPLAY_HEADER_TIP_ROUTER_LIST = [
      ROUTE_PATH.LIVE,
      ROUTE_PATH.MALL,
      ROUTE_PATH.HOME,
      ROUTE_PATH.PRODUCT,
      ROUTE_PATH.SEARCH,
      ROUTE_PATH.SEARCH_LIVE,
      ROUTE_PATH.SEARCH_MERCHANT,
      ROUTE_PATH.EVENT_DETAIL,
      ROUTE_PATH.CALENDAR,
    ]
    const currentRouterPath = router?.pathname
    const hasMatched = DISPLAY_HEADER_TIP_ROUTER_LIST.includes(currentRouterPath)

    return hasMatched
  }

  // FIXME:
  // 消除先前抽獎活動記錄在使用者端的 localStorage
  // 2024/03/11 之後再移除
  useEffect(() => {
    const hasLotteryEventCacheKey = !isNull(window.localStorage.getItem(STORAGE_DATA['2024_Chinese_New_Year_Lottery_Event']))

    if (!isEmpty(window) && hasLotteryEventCacheKey) {
      window.localStorage.removeItem(STORAGE_DATA['2024_Chinese_New_Year_Lottery_Event'])
    }
  }, [])

  return (
    <div
      id='layout'
      className={cx('layout', {
        isHeaderHidden,
        isFullHeight: isFullHeight,
        'layout--has-navigaction-bar': router?.pathname === ROUTE_PATH.MALL,
      })}
      data-has-tip={showHeaderTip()}
    >
      {!isHeaderHidden && <Header />}

      <div className={cx('layout-body', { isFullHeight })}>{children}</div>
      {common.showBackdrop && <Backdrop />}
      {showFooter() && <Footer />}
    </div>
  )
}

export default BasicLayout
