import handleLocationHrefDirect from '@/methods/url/handleLocationHrefDirect'

// Constants
import { envConfigs } from '@/constants/config'

type ParamsType = {
  router: any
}

const handleLogoutReturnToOriginalPath = ({ router }: ParamsType) => {
  const returnUrl = window.location.href
  const url = `${envConfigs?.logOutUrl}?returnUrl=${returnUrl}`

  handleLocationHrefDirect({ router, url })
}

export default handleLogoutReturnToOriginalPath
