import Logo from './svg/logo.svg'
import LogoStrawberry from './svg/logo-strawberry.svg'
import Background from './svg/background.svg'
import MobileBackground from './svg/mobile-background.svg'
import Avatar from './svg/avatar.svg'
import Signal from './svg/signal.svg'
import ChevronRight from './svg/chevron-right.svg'
import Hanger from './svg/hanger.svg'
import Protect from './svg/protect.svg'
import Heart from './svg/heart.svg'
import Magnifier from './svg/magnifier.svg'
import Share from './svg/share.svg'
import Send from './svg/send.svg'
import Good from './svg/good.svg'
import Vegetable from './svg/vegetable.svg'
import Beauty from './svg/beauty.svg'
import Pet from './svg/pet.svg'
import Speaker from './svg/speaker.svg'
import Icecream from './svg/icecream.svg'
import Rocket from './svg/rocket.svg'
import Like from './svg/like.svg'
import CartAdd from './svg/cart-add.svg'
import MicIcecream from './svg/mic-icecream.svg'
import ArrowRight from './svg/arrow-right.svg'
import Bag from './svg/bag.svg'
import ArrowDown from './svg/arrow-down.svg'
import Success from './svg/success.svg'
import Failure from './svg/failure.svg'
import Copy from './svg/copy.svg'
import ArrowLeft from './svg/arrow-left.svg'
import ArrowLeftWithoutSize from './svg/arrow-left-without-size.svg'
import Arrow from './svg/arrow.svg'
import Close from './svg/close.svg'
import Dropdown from './svg/dropdown.svg'
import ShopBag from './svg/shopbag.svg'
import Burger from './svg/burger.svg'
import Facebook from './svg/Facebook.svg'
import Instagram from './svg/Instagram.svg'
import Twitter from './svg/Twitter.svg'
import Microphone from './svg/microphone.svg'
import Store from './svg/store.svg'
import Chat from './svg/chat.svg'
import HollowShare from './svg/hollow-share.svg'
import Device from './svg/device.svg'
import HealthFood from './svg/healthfood.svg'
import DailyLife from './svg/dailylife.svg'
import Joy from './svg/joy.svg'
import FreeDeliver from './svg/freedeliver.svg'
import Deliver from './svg/deliver.svg'
import Edit from './svg/edit.svg'
import EditHead from './svg/edit-head.svg'
import Trashcan from './svg/trashcan.svg'
import Pin from './svg/pin.svg'
import Attention from './svg/attention.svg'
import Empty from './svg/empty.svg'
import PhoneClose from './svg/phone-close.svg'
import Mic from './svg/mic.svg'
import MicOff from './svg/mic-off.svg'
import Camera from './svg/camera.svg'
import CameraOff from './svg/camera-off.svg'
import Sound from './svg/sound.svg'
import SoundOff from './svg/sound-off.svg'
import Wifi from './svg/wifi.svg'
import WifiOff from './svg/wifi-off.svg'
import HangUp from './svg/hang-up.svg'
import Switch from './svg/switch.svg'
import Trend from './svg/trend.svg'
import Enter from './svg/enter.svg'
import FieldClear from './svg/field-clear.svg'
import Search from './svg/search.svg'
import Info from './svg/info.svg'
import MicConnect from './svg/mic-connect.svg'
import Google from './svg/Google.svg'
import Line from './svg/Line.svg'
import Apple from './svg/Apple.svg'
import FacebookThirdParty from './svg/Facebook-third-party.svg'
import Email from './svg/email.svg'
import Mobile from './svg/mobile.svg'
import Coffee from './svg/coffee.svg'
import Filming from './svg/filming.svg'
import Lines from './svg/lines.svg'
import Purchase from './svg/purchase.svg'
import Plus from './svg/plus.svg'
import More from './svg/more.svg'
import MoreShare from './svg/more-share.svg'
import MoreTrend from './svg/more-trend.svg'
import ShareLink from './svg/share-link.svg'
import ShareLine from './svg/share-line.svg'
import ShareInstagram from './svg/share-instagram.svg'
import ShareFacebook from './svg/share-facebook.svg'
import RoomShare from './svg/room-share.svg'
import FastForward from './svg/fast-forward.svg'
import FastBack from './svg/fast-back.svg'
import Pause from './svg/pause.svg'
import Play from './svg/play.svg'
import EyeOpen from './svg/eye-open.svg'
import EyeClose from './svg/eye-close.svg'
import MenuLine from './svg/menu-line.svg'
import Rotate from './svg/rotate.svg'
import Fullscreen from './svg/fullscreen.svg'
import PipPlayer from './svg/pip-player.svg'
import PipPlayerExpand from './svg/pip-player-expand.svg'
import Coupon from './svg/coupon.svg'
import Check from './svg/check.svg'
import Text from './svg/text.svg'
import IGSetting from './svg/ig-setting.svg'
import ArrowExternal from './svg/arrow-external.svg'
import SignalOrigin from './svg/signal-origin.svg'
import PlayOrigin from './svg/play-origin.svg'
import Human from './svg/human.svg'
import CloseSM from './svg/close-sm.svg'
import LongHandleSearch from './svg/long-handle-search.svg'
import SearchMagnifier from './svg/search-magnifier.svg'
import InputClear from './svg/input-clear.svg'
import Earth from './svg/earth.svg'
import Setting from './svg/setting.svg'
import Calendar from './svg/calendar.svg'
import ShoppingCartWhite from './svg/shopping-cart-white.svg'
import ShoppingCartBlack from './svg/shopping-cart-black.svg'
import ShopBagGradient from './svg/shopbag_gradient.svg'
import SignalGradient from './svg/signal_gradient.svg'
import ExclamationMark from './svg/exclamation_mark.svg'
import Hourglass from './svg/hourglass.svg'
import PopularZoneIconBrand from './svg/popular-zone-icon-brand.svg'
import PopularZoneIconProduct from './svg/popular-zone-icon-product.svg'
import Hot from './svg/hot.svg'

const Icon = {
  Logo,
  LogoStrawberry,
  Background,
  MobileBackground,
  Avatar,
  Signal,
  ChevronRight,
  Hanger,
  Protect,
  Heart,
  Magnifier,
  Share,
  Send,
  Good,
  Vegetable,
  Beauty,
  Pet,
  Speaker,
  Icecream,
  Rocket,
  Like,
  CartAdd,
  MicIcecream,
  ArrowRight,
  Bag,
  ArrowDown,
  Success,
  Failure,
  Copy,
  ArrowLeft,
  ArrowLeftWithoutSize,
  Close,
  Dropdown,
  Arrow,
  ShopBag,
  Burger,
  Facebook,
  Instagram,
  Twitter,
  Microphone,
  Store,
  Chat,
  HollowShare,
  Device,
  HealthFood,
  DailyLife,
  Joy,
  FreeDeliver,
  Deliver,
  Edit,
  EditHead,
  Trashcan,
  Pin,
  Attention,
  PhoneClose,
  Mic,
  MicOff,
  Camera,
  CameraOff,
  Sound,
  SoundOff,
  Wifi,
  WifiOff,
  HangUp,
  Switch,
  Trend,
  Empty,
  Enter,
  FieldClear,
  Search,
  Info,
  MicConnect,
  Google,
  Line,
  Apple,
  FacebookThirdParty,
  Email,
  Mobile,
  Coffee,
  Filming,
  Lines,
  Purchase,
  Plus,
  More,
  MoreShare,
  MoreTrend,
  ShareLink,
  ShareLine,
  ShareInstagram,
  ShareFacebook,
  RoomShare,
  FastForward,
  FastBack,
  Play,
  Pause,
  EyeOpen,
  EyeClose,
  MenuLine,
  Rotate,
  Fullscreen,
  PipPlayer,
  PipPlayerExpand,
  Coupon,
  Check,
  Text,
  IGSetting,
  ArrowExternal,
  SignalOrigin,
  PlayOrigin,
  Human,
  CloseSM,
  LongHandleSearch,
  SearchMagnifier,
  InputClear,
  Earth,
  Setting,
  Calendar,
  ShoppingCartWhite,
  ShoppingCartBlack,
  ShopBagGradient,
  SignalGradient,
  ExclamationMark,
  Hourglass,
  PopularZoneIconBrand,
  PopularZoneIconProduct,
  Hot,
}

export default Icon
