import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { isEmpty } from 'lodash'

// Libs
import { fetchMemberData } from '@/api/fetchMemberData'
import { fetchUserAuth } from '@/api/fetchUserAuth'
import { fetchStrawberryToken } from '@/api/fetchStrawberryToken'
import removeSearchParams from '@/methods/url/removeSearchParams'
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import { useAppDispatch } from '@/redux/hooks'
import { setMemberData } from '@/redux/reducers/member'
import handleLogoutReturnToOriginalPath from '@/methods/handleLogoutReturnToOriginalPath'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'
import { STORAGE_DATA } from '@/constants/storageData'
import { ERROR_CODE } from '@/constants/errorData'

// Types
import { ErrorData } from '@/types/index'
import handleLogout from '@/methods/handleLogout'

type MemberDataData = ErrorData & {
  memberData: object
  isMemberDataFetched: boolean
}

type QueryParams = {
  type: string
  token: string
  refreshToken: string
  ticket?: string
  account_id?: string // 草莓網用
  name?: string // 草莓網用
}

export const useMemberData = (): MemberDataData => {
  const dispatch = useAppDispatch()

  const router = useRouter()
  const { query = {}, isReady, asPath } = router

  const tokenFromLocalStorage = (typeof window !== 'undefined' && JSON.parse(<string>window.localStorage.getItem(STORAGE_DATA.TOKEN))) || {}

  const { token, refreshToken, ticket, account_id: accountId = '', name = '' } = query as QueryParams

  const handleRemoveQuery = () => {
    const newUrl = removeSearchParams({ removeKeyList: ['token', 'refreshToken', 'ticket', 'type', 'locale', 'jobId', 'account_id', 'name'] })

    const newAsPath = asPath.split('?')[0] // 這裡的 asPath 要把 query string 拿掉

    handleRoutePushWithQuery({ url: newUrl, router, pathname: newAsPath, isReplace: true })
  }

  // 草莓網 token
  useQuery([QUERY_KEY.FETCH_STRAWBERRY_TOKEN, { accountId: accountId, name }], () => fetchStrawberryToken({ accountId, name }), {
    enabled: isReady && !!accountId && !!name,
    onSuccess: (data) => {
      const { token, refreshToken, name } = data

      const tokenData = {
        token,
        refreshToken,
        name,
      }

      window.localStorage.setItem(STORAGE_DATA.TOKEN, JSON.stringify(tokenData))

      handleRemoveQuery()
    },
    onError: (error: ErrorData) => {
      if (error.errorCode === ERROR_CODE.TOKEN_EXPIRED) {
        window.localStorage.setItem(STORAGE_DATA.IS_TIMEOUT, 'true')
      }
    },
  })

  useQuery([QUERY_KEY.FETCH_USER_AUTH, { token }], () => fetchUserAuth({ token }), {
    enabled: !!token && isReady && isEmpty(tokenFromLocalStorage),
    onSuccess: (data) => {
      if (data?.auth) {
        const tokenData = { token, refreshToken }

        window.localStorage.setItem(STORAGE_DATA.TOKEN, JSON.stringify(tokenData))

        handleRemoveQuery()
      }
    },
    onError: (error: ErrorData) => {
      if (error.errorCode === ERROR_CODE.TOKEN_EXPIRED) {
        window.localStorage.setItem(STORAGE_DATA.IS_TIMEOUT, 'true')
      }
      window.localStorage.removeItem(STORAGE_DATA.TOKEN)
      handleLogoutReturnToOriginalPath({ router })
    },
  })

  const { data = [], isFetched } = useQuery([QUERY_KEY.FETCH_MEMBER_DATA], () => fetchMemberData(), {
    enabled: !token && isReady && !isEmpty(tokenFromLocalStorage) && !accountId,
    onSuccess: (data) => {
      dispatch(setMemberData({ memberData: { ...data } }))
    },
    onSettled: () => {
      dispatch(setMemberData({ memberData: { isFetched: true } }))
    },
  })

  useEffect(() => {
    if (isReady && ticket && !token) {
      // 因為 l_p_jwt 目前時效 2 小時，如果過期但 l_p_cc_0(時效9天) 還沒過期，就會導致 customs 登入，但導回 shop 的 url 沒帶 token
      // 這情況就前端強制登出
      handleRemoveQuery()

      setTimeout(() => {
        handleLogout({ router, dispatch })
      }, 1000)
    }
  }, [isReady, ticket, token])

  useEffect(() => {
    if (isReady && token && !isEmpty(tokenFromLocalStorage)) {
      // 假設同時有 query token 跟 localStorage token，就移除 query token
      // 一般來講是不會有這情境，但偶爾會出現，要再觀察一下怎麼重現
      handleRemoveQuery()
    }
  }, [token, tokenFromLocalStorage, isReady])

  return { memberData: isFetched && !data?.isResponseError ? data : {}, isMemberDataFetched: isFetched }
}
